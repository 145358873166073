//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

import GuideListTable from '@/modules/guide/components/guide-list-table.vue';
// import GuideListFilter from '@/modules/guide/components/guide-list-filter.vue';
// import GuideListToolbar from '@/modules/guide/components/guide-list-toolbar.vue';

export default {
  name: 'app-guide-list-page',

  components: {
    [GuideListTable.name]: GuideListTable,
    // [GuideListFilter.name]: GuideListFilter,
    // [GuideListToolbar.name]: GuideListToolbar,
  },
  computed:{
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
};
