import { render, staticRenderFns } from "./guide-list-page.vue?vue&type=template&id=6c35bf7e&scoped=true&"
import script from "./guide-list-page.vue?vue&type=script&lang=js&"
export * from "./guide-list-page.vue?vue&type=script&lang=js&"
import style0 from "./guide-list-page.vue?vue&type=style&index=0&id=6c35bf7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c35bf7e",
  null
  
)

export default component.exports
import {QBreadcrumbs,QIcon,QBreadcrumbsEl} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBreadcrumbs,QIcon,QBreadcrumbsEl})
