//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';
import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
import moment from 'moment';

import { GuidePermissions } from '@/modules/guide/guide-permissions';
import { GuideModel } from '@/modules/guide/guide-model';
const { fields } = GuideModel;

export default {
  name: 'app-guide-list-table',

  mounted() {
    this.doFilter();
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: '',
      selection:[],
      nextPage: 2,
      // pagination: {
      //   page: 1,
      //   rowsPerPage: 10,
      //    // rowsNumber: 100,
      //   // rowsNumber: xx if getting data from a server
      //   // sortBy: 'createdAt',
      //   // descending: false,
      // },
      columns: [
        {
          name: 'fullName',
          field: 'fullName',
          label: i18n('entities.guide.fields.fullName'), 
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'email',
          field: 'email',
          label: i18n('entities.guide.fields.email'),
          align: 'center',
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: i18n('entities.guide.fields.phoneNumber'),
          align: 'center',
        },
        {
          name: 'isMale',
          field: 'isMale',
          label: i18n('entities.guide.fields.gender'),
          align: 'center',
        },
        {
          name: 'wallet',
          field: 'wallet',
          label: i18n('entities.guide.fields.wallet'),
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: i18n('entities.guide.fields.createdAt'),
          align: 'center',
        },
        {
          name: 'status',
          field: 'status',
          label: i18n('entities.guide.fields.status'),
          align: 'center',
        },
        {
          name: 'action',
          field: 'action',
          label:  i18n('common.action'),
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows: 'guide/list/rows',
      count: 'guide/list/count',
      loading: 'guide/list/loading',
      toggleStatusloading: 'iam/view/statusLoading',
      pagination: 'guide/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loadMoreLastRecord: 'guide/list/loadMoreLastRecord',
      moreRows: 'guide/list/moreRows',
      rowsBatch: 'guide/list/rowsBatch',
      
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToEdit() {
      return new GuidePermissions(this.currentUser).edit;
    },
    fields() {
      return fields;
    },
    language(){
      return vueI18n.locale
    },
    // pagesNumber () {
    //   return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    // },
    // checkedValue: {
    //   get() {
    //     return (disabled) => {
    //       return !disabled;
    //     }
    //   },
    //   set(newValue) {
    //     console.log('New Value = ', newValue);
    //     // this.currentState = newValue;
    //     // this.$emit('change', newValue);
    //   },
    // },
  },
  methods: {
    ...mapActions({
      doChangeSort: 'guide/list/doChangeSort',
      doChangePaginationCurrentPage: 'guide/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'guide/list/doChangePaginationPageSize',
      doMountTable: 'guide/list/doMountTable',
      doFetchByVuexfire: 'guide/list/doFetchByVuexfire',
      doFetch: 'guide/list/doFetch',
      doToggleUserAccountStatus: 'iam/view/doToggleUserAccountStatus'
    }),

  //#region [ Helper Functions ]
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    roleDescriptionOf(roleId) {
      return Roles.descriptionOf(roleId);
    },
    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    presenter(row, fieldName) {
      const val = GuideModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = GuideModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = GuideModel.presenter(row, fieldName);
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(val) {
      // const val = GuideModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(GuideModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = GuideModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterGuide(row, fieldName, key) {
      const user = GuideModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(row, fieldName, key) {
      const val = GuideModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
  //#endregion

    async doFilter() {
      this.doFetchByVuexfire()
      // const filter = { accountType: 'guideFreelance' };
      // return this.doFetch({
      //   filter,
      // });
    },
    async toggleAccountStatus(id) {
      console.log(id);
      this.doToggleUserAccountStatus(id)
    },

  },
};
